import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import i18n from '@/lang/index.js'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/views/login/login'),
        meta: {
            title: i18n.t('login.title')
        }
    },
    {
        path: '/sso',
        name: 'sso',
        component: () => import('@/views/sso/jump'),
        meta: {
            title: i18n.t('login.title')
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/login'),
        meta: {
            title: i18n.t('login.title')
        }
    }
    // {
    //     path: '/password-reset',
    //     name: 'password-reset',
    //     component: passwordReset,
    //     meta: {
    //         title: i18n.t('button.password_reset')
    //     }
    // },
    // {
    //     path: '/verify',
    //     name: 'verify',
    //     component: verify,
    //     meta: {
    //         title: i18n.t('deng-lu-er-ci-yan-zheng')
    //     }
    // }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (process.env.VUE_APP_PROJECT === 'cuiqiu_auth_pri') {
        const { corp_name = '' } = store.state.corpCss
        document.title = corp_name === '' ? to.meta.title : `${to.meta.title}-${corp_name}`
    } else {
        document.title = to.meta.title
    }
    next()
})

export default router
