import axios from 'axios'
import qs from 'qs'
import Cookies from 'js-cookie'
import config from '@/config/config.js'
import { getLanguage } from '@/lang/index'
// import { Message } from 'element-ui'
axios.defaults.withCredentials = true
async function request (content) {
    try {
        const apiUrl = config.API_URL
        const { params = {}, method = 'post', path = '' } = content
        const token = Cookies.get(config.COOKIES.TOKEN)
        if (token) { params.token = token }
        params.language = getLanguage()
        params.host = window.location.hostname
        params.browser_language = navigator.language || navigator.userLanguage
        params.browser_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone
        let requestInfo = {}
        if (method === 'post') {
            requestInfo = {
                method: 'post',
                url: apiUrl + path,
                data: qs.stringify(params)
            }
        } else {
            requestInfo = {
                method: 'get',
                url: apiUrl + path,
                params
            }
        }

        const res = await axios(requestInfo)
        const { code = 200, msg = '' } = res.data || {}
        if (code !== 200) {
            console.error('request Err' + msg)
            throw msg
        }
        return res.data || {}
    } catch (err) {
        // Message({ message: err, type: 'error' })
        console.error('request Err' + err)
        throw err
    }
}
export default request
