if (process.env.VUE_APP_PROJECT === 'cuiqiu') {
    if (process.env.NODE_ENV === 'development') {
        module.exports = {
            API_URL: 'http://localhost:9061',
            RL_URL: 'http://localhost:9525',
            CLIENT_HOST: 'localhost',
            COOKIES: {
                SET_DOMAIN: 'localhost',
                TOKEN_USER: 'cuiqiu-client-user-token',
                TOKEN_MAIL: 'cuiqiu-client-mail-token',
                LOGIN_MAIL: 'cuiqiu-client-mail',
                CLIENT_API_URL: 'cuiqiu-client-api-url',
                RL_SPECAUTH: 'rlspecauth',
                RL_LOGO: 'cuiqiu-client-logo'
            }
        }
    } else if (process.env.NODE_ENV === 'test') {
        module.exports = {
            API_URL: 'https://domain-api-test.cuiqiu.com',
            RL_URL: './mail/index.html',
            CLIENT_HOST: 'mail-test.cuiqiu.com',
            COOKIES: {
                SET_DOMAIN: 'test.cuiqiu.com',
                TOKEN_USER: 'cuiqiu-client-user-token',
                TOKEN_MAIL: 'cuiqiu-client-mail-token',
                LOGIN_MAIL: 'cuiqiu-client-mail',
                CLIENT_API_URL: 'cuiqiu-client-api-url',
                RL_SPECAUTH: 'rlspecauth',
                RL_LOGO: 'cuiqiu-client-logo'
            }
        }
    } else {
        module.exports = {
            API_URL: 'https://domain-api.cuiqiu.com',
            RL_URL: './mail/index.html',
            CLIENT_HOST: 'mail-client.cuiqiu.com',
            COOKIES: {
                SET_DOMAIN: 'cuiqiu.com',
                TOKEN_USER: 'cuiqiu-client-user-token',
                TOKEN_MAIL: 'cuiqiu-client-mail-token',
                LOGIN_MAIL: 'cuiqiu-client-mail',
                CLIENT_API_URL: 'cuiqiu-client-api-url',
                RL_SPECAUTH: 'rlspecauth',
                RL_LOGO: 'cuiqiu-client-logo'
            }
        }
    }
}
if (process.env.VUE_APP_PROJECT === 'getmx') {
    if (process.env.NODE_ENV === 'development') {
        module.exports = {
            API_URL: 'http://localhost:9061',
            RL_URL: 'http://localhost:9256',
            CLIENT_HOST: 'localhost',
            COOKIES: {
                SET_DOMAIN: 'localhost',
                TOKEN_USER: 'getmx-client-user-token',
                TOKEN_MAIL: 'getmx-client-mail-token',
                LOGIN_MAIL: 'getmx-client-mail',
                CLIENT_API_URL: 'getmx-client-api-url',
                RL_SPECAUTH: 'rlspecauth',
                RL_LOGO: 'getmx-client-logo'
            }
        }
    } else if (process.env.NODE_ENV === 'test') {
        module.exports = {
            API_URL: 'https://domain-api-test.getmx.com',
            RL_URL: './mail/index.html',
            CLIENT_HOST: 'mail-client-test.getmx.com',
            COOKIES: {
                SET_DOMAIN: 'test.getmx.com',
                TOKEN_USER: 'getmx-client-user-token',
                TOKEN_MAIL: 'getmx-client-mail-token',
                LOGIN_MAIL: 'getmx-client-mail',
                CLIENT_API_URL: 'getmx-client-api-url',
                RL_SPECAUTH: 'rlspecauth',
                RL_LOGO: 'getmx-client-logo'
            }
        }
    } else {
        module.exports = {
            API_URL: 'https://domain-api.getmx.com',
            RL_URL: './mail/index.html',
            CLIENT_HOST: 'mail-client.getmx.com',
            COOKIES: {
                SET_DOMAIN: 'getmx.com',
                TOKEN_USER: 'getmx-client-user-token',
                TOKEN_MAIL: 'getmx-client-mail-token',
                LOGIN_MAIL: 'getmx-client-mail',
                CLIENT_API_URL: 'getmx-client-api-url',
                RL_SPECAUTH: 'rlspecauth',
                RL_LOGO: 'getmx-client-logo'
            }
        }
    }
}
