import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'

import enLocale from '../../locales/en-US.json'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang

import zhCNLocale from '../../locales/zh-CN.json'
import elementZhCNLocale from 'element-ui/lib/locale/lang/zh-CN' // element-ui lang

import zhTWLocale from '../../locales/zh-TW.json'
import elementZhTWLocale from 'element-ui/lib/locale/lang/zh-TW'// element-ui lang

import esLocale from '../../locales/es.json'
import elementEsLocale from 'element-ui/lib/locale/lang/es' // element-ui lang

import jaLocale from '../../locales/ja.json'
import elementJaLocale from 'element-ui/lib/locale/lang/ja' // element-ui lang

import deLocale from '../../locales/de.json'
import elementDeLocale from 'element-ui/lib/locale/lang/de' // element-ui lang

import frLocale from '../../locales/fr.json'
import elementFrLocale from 'element-ui/lib/locale/lang/fr' // element-ui lang

import nbNoLocale from '../../locales/nb-NO.json'
import elementNbNOLocale from 'element-ui/lib/locale/lang/nb-NO' // element-ui lang

import nlNLLocale from '../../locales/nl-NL.json'
import elementNlNLLocale from 'element-ui/lib/locale/lang/nl' // element-ui lang

import ptBRLocale from '../../locales/pt-BR.json'
import elementPtBRLocale from 'element-ui/lib/locale/lang/pt' // element-ui lang

import ruLocale from '../../locales/ru.json'
import elementRuLocale from 'element-ui/lib/locale/lang/ru-RU' // element-ui lang

import svSELocale from '../../locales/sv-SE.json'
import elementSvSELocale from 'element-ui/lib/locale/lang/sv-SE' // element-ui lang

import trLocale from '../../locales/tr.json'
import elementTrLocale from 'element-ui/lib/locale/lang/tr-TR' // element-ui lang

import ukUALocale from '../../locales/uk-UA.json'
import elementUkUALocale from 'element-ui/lib/locale/lang/ua' // element-ui lang

Vue.use(VueI18n)

const messages = {
    'en-US': {
        ...enLocale,
        ...elementEnLocale
    },
    'zh-CN': {
        ...zhCNLocale,
        ...elementZhCNLocale
    },
    'zh-TW': {
        ...zhTWLocale,
        ...elementZhTWLocale
    },
    es: {
        ...esLocale,
        ...elementEsLocale
    },
    ja: {
        ...jaLocale,
        ...elementJaLocale
    },
    de: {
        ...deLocale,
        ...elementDeLocale
    },
    fr: {
        ...frLocale,
        ...elementFrLocale
    },
    'nb-NO': {
        ...nbNoLocale,
        ...elementNbNOLocale
    },
    'nl-NL': {
        ...nlNLLocale,
        ...elementNlNLLocale
    },
    'pt-BR': {
        ...ptBRLocale,
        ...elementPtBRLocale
    },
    ru: {
        ...ruLocale,
        ...elementRuLocale
    },
    'sv-SE': {
        ...svSELocale,
        ...elementSvSELocale
    },
    tr: {
        ...trLocale,
        ...elementTrLocale
    },
    'uk-UA': {
        ...ukUALocale,
        ...elementUkUALocale
    }
}
export function getLanguage () {
    const chooseLanguage = Cookies.get('language')
    if (chooseLanguage && chooseLanguage !== '') return chooseLanguage

    // if has not choose language
    const language = (
        navigator.language || navigator.browserLanguage
    ).toLowerCase()
    const locales = Object.keys(messages)
    for (const locale of locales) {
        if (language.indexOf(locale.toLowerCase()) > -1) {
            return locale
        }
    }
    if (process.env.VUE_APP_PROJECT === 'cuiqiu') {
        return 'zh-CN'
    }
    return 'en-US'
}
const i18n = new VueI18n({
    // set locale
    // options: en | zh | es
    locale: getLanguage(),
    // set locale messages
    messages
})

export default i18n
