<template>
    <div id="app">
        <router-view/>
    </div>
</template>
<script>
import { GetOemClientDetail } from '@/api/oem'
import { mapState } from 'vuex'
import config from '@/config/config.js'
import Cookies from 'js-cookie'
export default {
    computed: mapState({
        oemModel: state => state.oemModel
    }),
    async mounted () {
        const host = location.hostname
        if (host === config.CLIENT_HOST) {
            this.$store.commit('setOemModel', -1)
        } else {
            this.$store.commit('setOemModel', 1)
        }

        if (this.oemModel === -1) {
            // 脆球官方
            this.$store.commit('setOem', {
                status: 1,
                show_status: 1,
                oem_title: process.env.VUE_APP_TITLE,
                oem_desc: this.$t('layout.title_child'),
                oem_logo: '',
                oem_bg: require('./assets/bg.jpg')
            })
            // OEM定制
            document.title = `${document.title}-${process.env.VUE_APP_TITLE}`
        } else {
            this.initOem()
        }
    },
    methods: {
        async initOem () {
            try {
                const { data = {} } = await GetOemClientDetail()
                this.$store.commit('setOem', data)
                document.title = `${document.title}-${data.oem_title}`

                const { show_status = -1, powered_show_status = 1, oem_logo = '' } = data

                // 未上线
                if (show_status === -1) {
                    this.$alert(`${this.$t('yu-ming')}[${location.hostname}] ${this.$t('wei-fa-bu-shang-xian')}`, this.$t('ti-shi'), {
                        type: 'error'
                    }).then(() => {
                        location.href = `https://${config.CLIENT_HOST}`
                    })
                }

                // 已上线
                if (show_status === 1) {
                    // LOGO
                    if (oem_logo !== '') {
                        Cookies.set(config.COOKIES.RL_LOGO, oem_logo)
                    }

                    // 版权
                    if (powered_show_status === -1) {
                        document.getElementById('powered').style.display = 'none'
                    }
                }
            } catch (err) {
                this.$message.error(err)
            }
        }
    }
}
</script>
<style>
*{
    padding: 0;
    margin: 0;
}
a{
    color: #717f99;
    text-decoration: unset;
}
a:hover{
    color: #ff4800;
}
</style>
