import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state () {
        return {
            oem: {},
            oemModel: -1
        }
    },
    mutations: {
        setOem (state, value) {
            state.oem = value
        },
        setOemModel (state, value) {
            state.oemModel = value
        }
    },
    actions: {
    },
    modules: {
    }
})
